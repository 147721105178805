<template>
  <div style="height: 93.5vh"> <!-- Make it 89vh if the searchbar is included -->
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <!-- Baseline Entity List -->
    <div class="email-app-list">

      <!--&lt;!&ndash; App Searchbar Header &ndash;&gt;-->
      <!--<div class="app-fixed-search d-flex align-items-center">-->

      <!--  &lt;!&ndash; Toggler &ndash;&gt;-->
      <!--  <div class="sidebar-toggle d-block d-lg-none ml-1">-->
      <!--    <feather-icon-->
      <!--      icon="MenuIcon"-->
      <!--      size="21"-->
      <!--      class="cursor-pointer"-->
      <!--      @click="mqShallShowLeftSidebar = true"-->
      <!--    />-->
      <!--  </div>-->

      <!--  &lt;!&ndash; Searchbar &ndash;&gt;-->
      <!--  <div class="d-flex align-content-center justify-content-between w-100">-->
      <!--    <b-input-group class="input-group-merge">-->
      <!--      <b-input-group-prepend is-text>-->
      <!--        <feather-icon-->
      <!--          icon="SearchIcon"-->
      <!--          class="text-muted"-->
      <!--        />-->
      <!--      </b-input-group-prepend>-->
      <!--      <b-form-input-->
      <!--        :value="searchQuery"-->
      <!--        placeholder="Search requirements..."-->
      <!--        @input="updateRouteQuery"-->
      <!--      />-->
      <!--    </b-input-group>-->
      <!--  </div>-->
      <!--</div>-->

      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="requirementDiff in listData"
            :key="requirementDiff.id"
            :style="`background-color: ${resolveCompareTypeBackground(requirementDiff.compare_type)}`"
            no-body
            tag="li"
            @click="updateDiffViewData(requirementDiff)"
          >
            <b-media-aside class="media-left mr-50">
              <b-avatar
                class="avatar"
                :class="`bg-${resolveCompareTypeColor(requirementDiff.compare_type)}`"
                size="30"
              >
                {{ avatarInitial(requirementDiff.compare_type) }}
              </b-avatar>
              <div class="user-action">
                <!--<b-form-checkbox-->
                <!--  :checked="selectedEmails.includes(email.id)"-->
                <!--  @change="toggleSelectedMail(email.id)"-->
                <!--  @click.native.stop-->
                <!--/>-->
                <!--<div class="email-favorite">-->
                <!--  <feather-icon-->
                <!--    icon="StarIcon"-->
                <!--    size="17"-->
                <!--    :class="{ 'text-warning fill-current': email.isStarred }"-->
                <!--    @click.stop="toggleStarred(email)"-->
                <!--  />-->
                <!--</div>-->
              </div>
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    {{ requirementDiff.display_id }}
                  </h5>
                  <div v-if="requirementDiff.object_text" v-sanitized-html="requirementDiff.object_text" />
                  <div v-else-if="requirementDiff.changed.object_text" v-sanitized-html="requirementDiff.changed.object_text.new" />
                  <div v-else-if="requirementDiff.inCommon.object_text" v-sanitized-html="requirementDiff.inCommon.object_text" />
                </div>
                <div class="mail-meta-item">
                  <span v-if="requirementDiff.created" class="mail-date text-nowrap">
                    <!--TODO Implement a Requirement deletion date -->
                    Created: {{ requirementDiff.created | shortDate }}
                  </span>
                  <span
                    v-else-if="requirementDiff.changed && requirementDiff.changed.updated"
                    class="mail-date text-nowrap"
                  >
                    Updated: {{ requirementDiff.changed.updated.new | shortDate }}
                  </span>
                  <span
                    v-else-if="requirementDiff.added && requirementDiff.added.updated"
                    class="mail-date text-nowrap"
                  >
                    Updated: {{ requirementDiff.added.updated | shortDate }}
                  </span>
                  <span v-else class="mail-date text-nowrap">
                    Updated: <span class="font-small-1 text-muted">n/a</span>
                  </span>
                </div>
              </div>

              <div class="mail-message">
                <p class="text-truncate mb-0">
                  <!--{{ filterTags(email.message) }}-->
                </p>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
          :class="{'show': !listData.length}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Diff View/Detail -->
    <diff-details-view
      :class="{'show': showRequirementDiffDetails}"
      :diff-view-data="diffViewData"
      :opened-diff-meta="openedDiffMeta"
      @close-diff-details-view="showRequirementDiffDetails = false"
      @change-opened-requirement="changeOpenedEmail"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <baseline-left-sidebar
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

import BaselineLeftSidebar from './BaselineLeftSidebar.vue'
import DiffDetailsView from './DiffDetailsView.vue'
import baselineStoreModule from './baselineStoreModule'
import useBaselineView from './useBaselineView'

export default {
  name: 'BaselineDiffTable',
  components: {
    // BFormInput,
    // BInputGroup,
    // BInputGroupPrepend,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,

    VuePerfectScrollbar,

    BaselineLeftSidebar,
    DiffDetailsView,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-baseline'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, baselineStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const { resolveCompareTypeColor, avatarInitial, resolveCompareTypeBackground } = useBaselineView()

    const filterData = () => {
      store
        .dispatch(`${STORE_MODULE_NAME}/filterBaselineData`, route.value.params.filter)
        .then(data => { listData.value = data })
    }

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      filterData()
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })

    const searchQueryFn = () => { console.log('searchQuery triggered...') }
    watch(searchQuery, () => searchQueryFn())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    // ------------------------------------------------
    // Requirement List Data
    // ------------------------------------------------
    const listData = ref([])
    const fetchBaseline = () => {
      store
        .dispatch(`${STORE_MODULE_NAME}/fetchBaseline`, {
          specId: route.value.params.specId,
          baselineVer: route.value.params.ver,
        })
        .then(() => { filterData() })
    }
    fetchBaseline()

    // ------------------------------------------------
    // Requirement Diff Details
    // ------------------------------------------------
    const showRequirementDiffDetails = ref(false)
    const diffViewData = ref({})
    const openedDiffMeta = computed(() => {
      const openedDiffIndex = listData.value.findIndex(e => e.id === diffViewData.value.id)
      return {
        hasNextDiff: Boolean(listData.value[openedDiffIndex + 1]),
        hasPreviousDiff: Boolean(listData.value[openedDiffIndex - 1]),
      }
    })
    const updateDiffViewData = email => {
      diffViewData.value = email
      showRequirementDiffDetails.value = true
    }

    const changeOpenedEmail = dir => {
      const openedDiffIndex = listData.value.findIndex(e => e.id === diffViewData.value.id)
      const newDiffIndex = dir === 'previous' ? openedDiffIndex - 1 : openedDiffIndex + 1
      diffViewData.value = listData.value[newDiffIndex]
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showRequirementDiffDetails.value = false
    })

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // UI
      perfectScrollbarSettings,

      // Primary list information
      listData,

      // Requirement Diff Details
      showRequirementDiffDetails,
      diffViewData,
      updateDiffViewData,
      changeOpenedEmail,
      openedDiffMeta,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveCompareTypeColor,
      avatarInitial,
      resolveCompareTypeBackground,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
